export const words = {
  email: "correo electrónico",
  Email: "correo electrónico",
  password: "contraseña",
  login: "Iniciar sesión",
  pictures: "Fotos",
  copy_link: "Copiar enlace",
  share_with_whatsapp: "vía WhatsApp",
  qrdownload: "Descarga de código Qr",
  flyerdownload: "Descargar folleto",
  logout: "Cerrar sesión",
  challenge: "Desafío fotográfico",
  preparation: "Preparación",
  photos: 'Fotos',
  challenges: "Desafío",
  diashow: "Presentación en directo",
  settings: "Ajustes",
  of: "de",
  comments: "Comentarios",
  liked: "Como",
  answer: "Respuestas",
  minutes: " minutos",
  hours: " Horas",
  days: " Días",
  yesterday: "Ayer",
  hide: "Ocultar",
  share: "Comparte",
  report: "Informe",
  delete: "Borra",
  description: "Descripción de la",
  enter: "Únete a",
  install: "Instala",
  or: "o",
  event: "evento",
  save: "Guarda",
  cancel: "Cancela",
  continue: "Más información en",
  task: "Tarea",
  take_photo: "Haz una foto",
  skip: "Saltar",
  color: "Color",
  videos: "Vídeos",
  video: "Vídeo",
  eur: 'Euro',
  usd: "Dólar",
  packages: {
    free: "Gratis",
    base: "Base",
    upgrade_base_to_premium: 'Premium',
    upgrade_base_to_deluxe: 'Deluxe',
    premium: 'Premium',
    upgrade_premium_to_deluxe: 'Deluxe',
    deluxe: 'Deluxe'
  },
  1: "A",
  2: "Dos",
  3: "Tres",
  4: "Cuatro",
  5: "Cinco",
  download: "Descarga",
  are: "son",
  is: "es",
  mass_download: "Descarga masiva",
  upgrade: "Actualiza",
  start_now: "Comienza",
  live_diashow: "Presentación en directo",
  code: "Código",
  chosen: "seleccionado",
  all: "Todos",
  picture: "Fotografía",
  language: "Lengua",
  languages: {
    de: "Alemán",
    en: "Inglés",
    fr: "Francés",
    it: "Italiano",
    es: "Español"
  },
  close: "Cerrar",
  edit: "Edita",
  release: "Libera",
  check: "Consulta"
};