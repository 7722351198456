export const phrases = {
  your_party: "Jouw feestje.",
  all_pictures: "Alle foto's.",
  forgot_password: "Wachtwoord vergeten?",
  no_account_yet: "Heb je nog geen account? Registreer je nu.",
  send_your_event: "Nodig je gasten uit",
  scan_here: 'Hier scannen',
  show_more_replies: "meer antwoorden zien",
  shared_image_text: "Kijk eens naar deze foto, genomen tijdens een evenement met https://www.everlense.de",
  image_not_available_anymore: "Deze afbeelding is niet langer beschikbaar",
  add_images: "Afbeeldingen toevoegen",
  capture_photo: "Maak een foto",
  enter_your_name: "Voer je naam in",
  your_photo_app_for_weddings: "Jouw foto-app voor bruiloften",
  invite_guests: "Nodig gasten uit",
  delete_demo_data: "Demo-gegevens verwijderen",
  event_description_placeholder: "Wat wil je je gasten nog meer vertellen? Ga naar de instellingen om de beschrijving aan te passen.",
  start_slideshow: "Diashow hier starten",
  slideshow_steps_1: "Ga naar met je apparaat",
  slideshow_steps_2: "en voer het volgende in",
  slideshow_steps_3: "6-cijferige code",
  slideshow_steps_4: "in.",
  print_tasks: "Taken afdrukken",
  edit_tasks: "Taken beheren",
  challenge_tips_and_tricks_1: "Tips & trucs voor",
  challenge_tips_and_tricks_2: "Jouw perfecte uitdaging",
  challenge_tips_and_tricks_content_1: "Print je sjabloon uit op dik papier.",
  challenge_tips_and_tricks_content_2: "Verdeel de taken over elke zitplaats voor je feestje.",
  challenge_tips_and_tricks_content_3: "Vertel zoveel mogelijk mensen over de foto-uitdaging.",
  challenge_tips_and_tricks_content_4: "Gebruik de live diashow zodat alle gasten de kiekjes in realtime kunnen zien.",
  print_tasks_intro: "Om ervoor te zorgen dat je gasten de opdrachten van de foto-uitdaging kunnen vinden, moet je ze op het feest ophangen.",
  print_tasks_title_1: "Kaarten om uit te printen (populair)",
  print_tasks_title_2: "Alleen QR-codes exporteren (en zelf kaarten ontwerpen)",
  print_tasks_action_1: "PDF's downloaden",
  print_tasks_action_2: "QR-codes downloaden",
  print_tasks_description_1: "Je gasten kunnen meedoen aan de foto-uitdaging met behulp van de QR-codes op onze afdrukbare kaarten.",
  print_tasks_description_2: "Download QR codes om de foto-uitdaging te starten en gebruik ze om je eigen kaarten te ontwerpen. Er is één QR code per opdracht.",
  edit_tasks_title_1: "Selecteer de taken",
  edit_tasks_title_2: "voor je gasten",
  really_want_to_delete_task: "De taak echt verwijderen?",
  add_task_button: "Taak toevoegen",
  delete_all_tasks_button: "Alles verwijderen",
  settings_event_name_header: "Hoe moet je evenement heten?",
  settings_event_description_header: "Wat moeten je gasten nog meer weten?",
  settings_event_name_label: "Naam van het evenement",
  settings_event_description_label: "Beschrijving van het evenement",
  settings_password_title: "Wachtwoord wijzigen",
  settings_old_password_header: "Oud wachtwoord",
  settings_new_password_header: "Nieuw wachtwoord",
  settings_new_password_repeat_header: "Herhaal nieuw wachtwoord",
  settings_invite_admin_title: "Beheerders toevoegen",
  settings_invite_admin_header: "E-mailadres van de nieuwe beheerder",
  send_invite: "Uitnodiging versturen",
  settings_deactivate_challenge_title: "Uitdaging deactiveren",
  settings_deactivate_challenge_header: "De uitdaging activeren/deactiveren",
  settings_delete_data_title: "Account verwijderen",
  settings_delete_data_header: "Alle gegevens permanent verwijderen",
  delete_event: "Gebeurtenis verwijderen",
  delete_event_toast_content: "Klik op \"Verwijderen\" om je account definitief te sluiten. LET OP: Alle geüploade afbeeldingen, video's en gegevens zijn dan onherroepelijk verloren en kunnen niet meer hersteld worden, zelfs niet door het everlense team.",
  settings_legal: "Juridische zaken",
  settings_legal_content_1: "Met de creatie van je evenement heb je",
  settings_legal_content_2: "GTC",
  settings_legal_content_3: " en onze",
  settings_legal_content_4: "Privacybeleid ",
  settings_legal_content_5: "geaccepteerd. Als je meer wilt weten over everlense, bezoek dan onze ",
  settings_legal_content_6: "Afdruk.",
  reset_password: "Wachtwoord opnieuw instellen",
  register_phrase: "We hebben je e-mailadres nodig om de opzet van je evenement af te ronden.",
  register_phrase_booked: "De boeking is gelukt! We hebben je e-mailadres nodig om de opzet van je evenement af te ronden.",
  email_address: "E-mailadres",
  register_legal: "Door lid te worden, accepteer je onze <a class=\"underline\" href=\"https://www.everlense.de/agb\">algemene voorwaarden en</a> ons <a class=\"underline\" href=\"https://www.everlense.de/datenschutz\">privacybeleid</a>. Als je meer wilt weten over everlense, bezoek dan onze <a class=\"underline\" href=\"https://www.everlense.de/impressum\">juridische verklaring</a>. Door je aan te melden, ga je ook akkoord met het ontvangen van marketing e-mails van ons. Je kunt deze toestemming op elk moment intrekken.",
  install_everlense: "Everlense installeren",
  install_content_1: "Installeer everlense op je startscherm om de gebeurtenis nooit meer te vergeten.",
  install_content_2: "Klik op",
  install_content_3: "en dan \"Ga naar beginscherm\".",
  install_content_4: "Later",
  install_content_5: "Oké!",
  no_images_yet: "Er zijn nog geen foto's geüpload. Druk op de plusknop om een foto te uploaden.",
  delete_all_tasks_really: "Echt alle taken verwijderen?",
  your_task: "Jouw taak",
  change_colors: "Kleuren veranderen",
  primary_color: "Primaire kleur",
  background_color: "Achtergrondkleur",
  reset: "Reset",
  choose_color: "Selecteer kleur",
  upgrade_package: "Upgradepakket",
  upgrade_required: "Upgrade vereist",
  current_package: "Huidig pakket",
  used_image_count: "{used} van {max} beelden.",
  deleted_image_count: "{not_deleted} die momenteel in je galerie staan, {deleted} verwijderd.",
  used_downloads_count: "{used} gebruikt door {max} massadownloads.",
  most_popular: "Meest populair",
  price: '{price} Euro',
  max_media: "max. {count} beelden",
  max_guests: "max. {count} Gasten",
  infinite_guests: "Onbeperkt aantal gasten",
  storage_duration: "{duration} Maanden Opslagperiode",
  max_bulk_downloads: "gratis bulk downloaden | gratis bulk downloaden",
  custom_colors: "Individuele kleuren",
  multiple_admins: "Meerdere beheerders",
  whatsapp_support: "Premium WhatsApp-ondersteuning",
  go_to_checkout: "Naar de kassa",
  start_setup: "Instelling starten",
  upgrade_toast_content: "Om deze functie te kunnen gebruiken, moet je je everlense pakket upgraden. Bekijk de verschillende opties. ",
  no_thank_you: "Nee, dank je.",
  i_want_to_know_more: "Meer leren",
  demo_event_title: 'Lisa & Jan',
  how_do_you_want_to_reedem: "Hoe wil je je pakket inwisselen?",
  currently_logged_in_event: "Je bent momenteel aangemeld bij het evenement: {name} Wil je je aankoop toepassen op dit evenement?",
  choose_event: "Voor welk evenement wil je je aankoop doen?",
  use_for_current_event: "huidige gebeurtenis",
  use_for_existing_event: "bestaand evenement",
  use_for_new_event: "nieuw evenement",
  settings_event_date_header: "Wanneer vindt je evenement plaats?",
  settings_event_date_label: "Datum van het evenement",
  sort: "Sorteer",
  time_of_recording: "Opnametijd",
  time_of_upload: "Uploadtijd",
  lets_setup_event: "Laten we samen je evenement organiseren",
  we_will_help_you_setup: "Wij helpen je met het opzetten van je everlensevenement in slechts 2 minuten. Heb je er zin in?",
  what_is_the_name: "Hoe moet je evenement heten?",
  cancel_setup: "Annuleren",
  enter_event_name_here: "Voer hier de naam van het evenement in",
  when_does_event_take_place: "Wanneer vindt je evenement plaats?",
  enter_event_date_here: "Voer hier de datum van het evenement in",
  nice_please_upload_photo: "Heel leuk. Upload nu een foto van een evenement om het nog persoonlijker te maken",
  select_photo: "Selecteer foto",
  what_do_you_want_to_describe: "Wauw! Wil je je gasten nog iets anders vertellen?",
  enter_description_here: "Voer hier een beschrijving in",
  finish_setup: "Installatie afronden",
  successfull_save: "Succesvol opgeslagen",
  successfull_deletion: "Succesvol verwijderd",
  copied_link: "Link gekopieerd",
  booking_successfull: "Succesvol boeken",
  completed_setup: "Installatie voltooid",
  sent_invite: "Uitnodiging verstuurd",
  initiated_payout: "Uitbetaling gecreëerd",
  sent_email: "E-mail verzonden",
  max_ten_images: "Maximaal 10 afbeeldingen tegelijk.",
  reached_download_limit: "Downloadlimiet van het evenement bereikt.",
  reached_mass_download_limit: "Maximum aantal massadownloads bereikt.",
  no_images_were_uploaded: "Er zijn nog geen foto's geüpload.",
  booking_failed: "Reservering mislukt.",
  package_already_retrieved: "Pakket al ingewisseld",
  download_all_pictures: "Alle afbeeldingen downloaden",
  notice: "Opmerking",
  in_your_package: "In je pakket",
  bulk_download_modal_2: "van alle foto's in één keer. Als je nu op \"Downloaden\" klikt, krijg je het volgende te zien",
  bulk_download_modal_3: "een van deze",
  bulk_download_modal_4: "deze",
  bulk_download_modal_5: "download",
  bulk_download_modal_6: "Je hebt alle bulkdownloads in je pakket opgebruikt. Boek een upgrade om alle afbeeldingen te downloaden.",
  bulk_download_modal_7: "Even geduld alstublieft. Je afbeeldingen worden klaargemaakt om te downloaden.",
  donwload_modal_1: '',
  donwload_modal_2: "echt downloaden?",
  delete_modal_1: '',
  delete_modal_2: "echt verwijderen?",
  download_preparation: "Je download wordt voorbereid. Je ontvangt uiterlijk over 15 minuten een e-mail met je persoonlijke downloadlink.",
  save_pictures: "Afbeeldingen opslaan",
  delete_pictures: "Afbeeldingen verwijderen",
  upload_in_progress: "Lopend uploaden",
  change_language: "Taal veranderen",
  change_language_info: "We hebben everlense zo ontwikkeld dat de taal zich automatisch aanpast aan de browsertaal van de apparaten van je gasten. Dus als je internationale gasten op je bruiloft hebt, is dit geen probleem en is de fotogalerij altijd voor iedereen te begrijpen.",
  multiple_languages_1: "Meertaligheid",
  multiple_languages_2: "(Engels & Duits)",
  okay: "Oké!",
  download_pictures: "Afbeeldingen downloaden",
  max_ten_images_go_to_settings: "Maximaal 10 afbeeldingen toegestaan. Ga naar de instellingen om alle afbeeldingen te downloaden.",
  your_images_are_ready: "Joepie, je foto's zijn klaar!",
  here_are_your_images: "Hier zijn de foto's van je evenement {event}. Veel plezier!",
  ps_mass_download: "PS: Download de delen gewoon achter elkaar. Dit wordt alleen geteld als een bulk download.",
  download_here: "Hier downloaden",
  download_part: "Download onderdeel {part}",
  switch_language: "Taal veranderen",
  tasks_choose_language: "Welke taal moeten de voorbeeldtaken hebben?",
  successfull_task_recreation: "Taal van de voorbeeldtaken is gewijzigd",
  something_went_wrong: "Er is iets misgegaan",
  a_small_present_for_you: "Een cadeautje voor jou!",
  present_description: "Wil je everlense gebruiken op jouw evenement? <span class=\"font-medium\">Dan hebben wij een verrassing voor je</span>. We sturen het naar je e-mailadres.",
  your_email_address: "Je e-mailadres",
  save_discount: "Verzenden",
  accept_agb_and_mail: "Ik ga akkoord met de <a href=\"https://www.everlense.de/agb\" class=\"font-medium cursor-pointer\">algemene voorwaarden en</a> wil graag e-mails van everlense ontvangen.",
  thank_you_for_sending: "We sturen je binnen 15 minuten een e-mail met je persoonlijke verrassing.",
  discount_saved: "Verrassing verzekerd!",
  was_reported: "{type} Een gast heeft dit gemeld.",
  what_shall_happen: "{type} Wat moet hiermee gebeuren?",
  edit_reporting_description: "{type} Controleer het en beslis of het opnieuw moet worden vrijgegeven of permanent moet worden verwijderd. We hebben de afbeelding ondertussen verborgen.",
  how_did_you_get_to_know_us: "Hoe heb je over everlense gehoord?",
  please_select: "Selecteer",
  friends_and_family: "Vrienden & kennissen",
  seen_at_a_wedding: "Gezien op een bruiloft",
  other: "Diverse",
  settings_deactive_challenge_text_download: "Afbeeldingen labelen tijdens het downloaden",
  your_payment_is_processing: "Je betaling wordt verwerkt. Even geduld alstublieft."
};