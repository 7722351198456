export const words = {
  email: "E-mail",
  Email: "E-mail",
  password: "Mot de passe",
  login: "Se connecter",
  pictures: "Images",
  copy_link: "Copier le lien",
  share_with_whatsapp: 'via WhatsApp',
  qrdownload: "Télécharger le code Qr",
  flyerdownload: "Télécharger le dépliant",
  logout: "Se déconnecter",
  challenge: "Défi photo",
  preparation: "Préparation",
  photos: "Photos",
  challenges: 'Challenge',
  diashow: "Diaporama en direct",
  settings: "Paramètres",
  of: "de",
  comments: "Commentaires",
  liked: "J'aime",
  answer: "Répondre",
  minutes: " minutes",
  hours: " heures",
  days: " jours",
  yesterday: "Hier",
  hide: "Masquer",
  share: "Partager",
  report: "Signaler",
  delete: "Supprimer",
  description: "Description",
  enter: "Rejoindre",
  install: "Installer",
  or: "ou",
  event: "événement",
  save: "Sauver",
  cancel: "Annuler",
  continue: "Continuer",
  task: "Tâche",
  take_photo: "Prendre une photo",
  skip: "Sauter",
  color: "Couleur",
  videos: "Vidéos",
  video: "Vidéo",
  eur: 'Euro',
  usd: 'Dollar',
  packages: {
    free: "Gratuit",
    base: "Base",
    upgrade_base_to_premium: 'Premium',
    upgrade_base_to_deluxe: 'Deluxe',
    premium: 'Premium',
    upgrade_premium_to_deluxe: 'Deluxe',
    deluxe: 'Deluxe'
  },
  1: "Un",
  2: "Deux",
  3: "Trois",
  4: "Quatre",
  5: "Cinq",
  download: "Télécharger",
  are: "sont",
  is: "est",
  mass_download: "Téléchargement en masse",
  upgrade: "Mise à niveau",
  start_now: "Démarrer",
  live_diashow: "Diaporama en direct",
  code: 'Code',
  chosen: "sélectionné",
  all: "Tous les",
  picture: "Image",
  language: "Langue",
  languages: {
    de: "Allemand",
    en: "Anglais",
    fr: "Français",
    it: "Italien",
    es: "Espagnol"
  },
  close: "Fermer",
  edit: "Éditer",
  release: "Partager",
  check: "Vérifier"
};