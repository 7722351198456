export const phrases = {
  your_party: 'Deine Party.',
  all_pictures: 'Alle Bilder.',
  forgot_password: 'Passwort vergessen?',
  no_account_yet: 'Du hast noch keinen Account? Jetzt registrieren.',
  send_your_event: 'Lade deine Gäste ein',
  scan_here: 'Hier scannen',
  show_more_replies: 'weitere Antworten ansehen',
  shared_image_text: 'Schau mal welches Bild auf einem Event mit https://www.everlense.de entstanden ist',
  image_not_available_anymore: 'Dieses Bild ist nicht mehr verfügbar',
  add_images: 'Bilder hinzufügen',
  capture_photo: 'Foto aufnehmen',
  enter_your_name: 'Gib deinen Namen ein',
  your_photo_app_for_weddings: 'Deine Foto-App für Hochzeiten',
  invite_guests: 'Gäste einladen',
  delete_demo_data: 'Demodaten löschen',
  event_description_placeholder: 'Was möchtest du deinen Gästen noch mitteilen? Gehe in die Einstellungen, um die Beschreibung anzupassen.',
  start_slideshow: 'Slideshow hier starten',
  slideshow_steps_1: 'Gehe mit deinem Gerät auf',
  slideshow_steps_2: 'und gib den folgenden',
  slideshow_steps_3: "6-stelligen Code",
  slideshow_steps_4: 'ein.',
  print_tasks: 'Aufgaben drucken',
  edit_tasks: 'Aufgaben verwalten',
  challenge_tips_and_tricks_1: 'Tipps & Tricks für',
  challenge_tips_and_tricks_2: 'deine perfekte Challenge',
  challenge_tips_and_tricks_content_1: 'Drucke deine Vorlage auf dickem Papier aus.',
  challenge_tips_and_tricks_content_2: 'Verteile die Aufgaben vor deiner Feier auf jedem Platz.',
  challenge_tips_and_tricks_content_3: 'Erzähle möglichst vielen Leuten von der Foto-Challenge.',
  challenge_tips_and_tricks_content_4: 'Nutze die Live-Slideshow, damit alle Gäste die Schnappschüsse in Echtzeit sehen können.',
  print_tasks_intro: 'Damit deine Gäste die Aufgaben der Foto-Challenge auch finden, solltest du sie auf der Feier auslegen.',
  print_tasks_title_1: 'Karten zum Audrucken (beliebt)',
  print_tasks_title_2: 'Nur QR-Codes exportieren (und Karten selbst gestalten)',
  print_tasks_action_1: 'PDFs herunterladen',
  print_tasks_action_2: 'QR-Codes herunterladen',
  print_tasks_description_1: 'Über die QR-Codes auf unseren ausdruckbaren Karten können deine Gäste der Foto-Challenge beitreten.',
  print_tasks_description_2: 'QR-Codes zum Starten der Foto-Challenge herunterladen und für selbstgestaltete Karten nutzen. Pro Aufgabe gibt es einen QR-Code.',
  edit_tasks_title_1: 'Wähle die Aufgaben',
  edit_tasks_title_2: 'für deine Gäste',
  really_want_to_delete_task: 'Aufgabe wirklich löschen?',
  add_task_button: 'Aufgabe hinzufügen',
  delete_all_tasks_button: 'Alle löschen',
  settings_event_name_header: 'Wie soll dein Event heißen?',
  settings_event_description_header: 'Was müssen deine Gäste noch wissen?',
  settings_event_name_label: 'Name des Events',
  settings_event_description_label: 'Beschreibung des Events',
  settings_password_title: 'Passwort ändern',
  settings_old_password_header: 'Altes Passwort',
  settings_new_password_header: 'Neues Passwort',
  settings_new_password_repeat_header: 'Neues Passwort wiederholen',
  settings_invite_admin_title: 'Admins hinzufügen',
  settings_invite_admin_header: 'E-Mail Adresse des neuen Admins',
  send_invite: 'Einladung absenden',
  settings_deactivate_challenge_title: 'Challenge deaktivieren',
  settings_deactivate_challenge_header: 'Aktiviere/Deaktiviere die Challenge',
  settings_delete_data_title: 'Account löschen',
  settings_delete_data_header: 'Alle Daten endgültig löschen',
  delete_event: 'Event löschen',
  delete_event_toast_content: 'Klicke "Löschen", um deinen Account endgültig zu schließen. ACHTUNG: Alle hochgeladenen Bilder, Videos und Daten sind danach unwiderruflich verloren und können selbst vom everlense-Team nicht mehr wiederhergestellt werden.',
  settings_legal: 'Rechtliches',
  settings_legal_content_1: 'Mit der Erstellung deines Events hast du unsere',
  settings_legal_content_2: 'AGB',
  settings_legal_content_3: ' und unsere',
  settings_legal_content_4: 'Datenschutzerklärung ',
  settings_legal_content_5: 'akzeptiert. Wenn du mehr über everlense erfahren möchtest, besuche unser ',
  settings_legal_content_6: 'Impressum.',
  reset_password: 'Passwort zurücksetzen',
  register_phrase: 'Um die Einrichtung deines Events abzuschließen, benötigen wir deine E-Mail Adresse.',
  register_phrase_booked: 'Die Buchung war erfolgreich! Um die Einrichtung deines Events abzuschließen, benötigen wir deine E-Mail Adresse.',
  email_address: 'E-Mail Adresse',
  register_legal: 'Mit dem Beitritt akzeptierst du unsere <a class="underline" href="https://www.everlense.de/agb">AGB</a> und unsere <a class="underline" href="https://www.everlense.de/datenschutz">Datenschutzerklärung</a>. Wenn du mehr über everlense erfahren möchtest, besuche unser <a class="underline" href="https://www.everlense.de/impressum">Impressum</a>. Durch die Anmeldung erklärst du dich außerdem damit einverstanden, Marketing-E-Mails von uns zu erhalten. Du kannst diese Zustimmung jederzeit widerrufen.',
  install_everlense: 'Installiere everlense',
  install_content_1: 'Installiere everlense auf deinem Home-Bildschirm um das Event nie zu vergessen.',
  install_content_2: 'Klicke',
  install_content_3: 'und dann "Zum Home-Bildschirm".',
  install_content_4: 'Später',
  install_content_5: 'Alles klar!',
  no_images_yet: 'Bisher wurden noch keine Bilder hochgeladen. Drücke auf das Plus, um ein Bild hochzuladen.',
  delete_all_tasks_really: 'Alle Aufgaben wirklich löschen?',
  your_task: 'Deine Aufgabe',
  change_colors: 'Farben ändern',
  primary_color: 'Primärfarbe',
  background_color: 'Hintergrundfarbe',
  reset: 'Zurücksetzen',
  choose_color: 'Farbe wählen',
  upgrade_package: 'Paket upgraden',
  upgrade_required: 'Upgrade erforderlich',
  current_package: 'Aktuelles Paket',
  used_image_count: '{used} von {max} Bildern genutzt.',
  deleted_image_count: '{not_deleted} aktuell in deiner Galerie, {deleted} gelöscht.',
  used_downloads_count: '{used} von {max} Massendownloads genutzt.',
  most_popular: 'Am beliebtesten',
  price: '{price} Euro',
  max_media: 'max. {count} Bilder',
  max_guests: 'max. {count} Gäste',
  infinite_guests: 'unbegrenzte Gästeanzahl',
  storage_duration: '{duration} Monate Speicherdauer',
  max_bulk_downloads: 'kostenloser Massendownload | kostenlose Massendownloads',
  custom_colors: 'Individuelle Farben',
  multiple_admins: 'Mehrere Admins',
  whatsapp_support: 'Premium WhatsApp Support',
  go_to_checkout: 'Zum Checkout',
  start_setup: 'Einrichtung starten',
  upgrade_toast_content: 'Um diese Funktion nutzen zu können, musst du dein everlense Paket upgraden. Schaue dir die verschiedenen Möglichkeiten an. ',
  no_thank_you: 'Nein, Danke.',
  i_want_to_know_more: 'Mehr erfahren',
  demo_event_title: 'Lisa & Jan',
  how_do_you_want_to_reedem: 'Wie möchtest du dein Paket einlösen?',
  currently_logged_in_event: 'Du bist aktuell in dem Event: {name} eingeloggt. Möchtest du deinen Kauf auf dieses Event anwenden?',
  choose_event: 'Auf welches Event möchtest du deinen Kauf anwenden?',
  use_for_current_event: 'aktuelles Event',
  use_for_existing_event: 'bestehendes Event',
  use_for_new_event: 'neues Event',
  settings_event_date_header: 'Wann findet dein Event statt?',
  settings_event_date_label: 'Datum des Events',
  sort: 'Sortieren',
  time_of_recording: 'Aufnahmezeitpunkt',
  time_of_upload: 'Uploadzeitpunkt',
  lets_setup_event: 'Lass uns dein Event gemeinsam einrichten',
  we_will_help_you_setup: 'Wir helfen dir dabei, dein everlense Event in nur 2 Minuten einzurichten. Hast du Lust?',
  what_is_the_name: 'Wie soll der Name deines Events lauten?',
  cancel_setup: 'Einrichtung abbrechen',
  enter_event_name_here: 'Hier Eventname eingeben',
  when_does_event_take_place: 'Wann findet dein Event statt?',
  enter_event_date_here: 'Hier Eventdatum eingeben',
  nice_please_upload_photo: 'Sehr schön. Lade jetzt ein Event-Foto hoch, um es noch persönlicher zu machen',
  select_photo: 'Foto auswählen',
  what_do_you_want_to_describe: 'Wow! Möchtest du deinen Gästen noch etwas mitteilen?',
  enter_description_here: 'Hier Beschreibung eingeben',
  finish_setup: 'Einrichtung abschließen',
  successfull_save: 'Erfolgreich gespeichert',
  successfull_deletion: 'Erfolgreich gelöscht',
  copied_link: 'Link kopiert',
  booking_successfull: 'Buchung erfolgreich',
  completed_setup: 'Einrichtung abgeschlossen',
  sent_invite: 'Einladung abgeschickt',
  initiated_payout: 'Auszahlung angelegt',
  sent_email: 'E-Mail abgeschickt',
  max_ten_images: 'Maximal 10 Bilder auf einmal.',
  reached_download_limit: 'Downloadlimit des Events erreicht.',
  reached_mass_download_limit: 'Maximal Anzahl an Massendownloads erreicht.',
  no_images_were_uploaded: 'Es wurden noch keine Bilder hochgeladen.',
  booking_failed: 'Buchung fehlgeschlagen.',
  package_already_retrieved: 'Paket bereits eingelöst',
  download_all_pictures: 'Alle Bilder herunterladen',
  notice: 'Hinweis',
  in_your_package: 'In deinem Paket',
  bulk_download_modal_2: 'aller Fotos auf einmal enthalten. Wenn du jetzt auf "Download" klickst verbrauchst du',
  bulk_download_modal_3: 'einen dieser',
  bulk_download_modal_4: 'diesen',
  bulk_download_modal_5: 'Download',
  bulk_download_modal_6: 'Du hast alle Massendownloads deines Pakets verbraucht. Bitte buche ein Upgrade, um alle Bilder herunterzuladen.',
  bulk_download_modal_7: 'Bitte warten. Deine Bilder werden zum Download vorbereitet.',
  donwload_modal_1: '',
  donwload_modal_2: 'wirklich herunterladen?',
  delete_modal_1: '',
  delete_modal_2: 'wirklich löschen?',
  download_preparation: 'Dein Download wird vorbereitet. Du bekommst in spätestens 15 Minuten eine E-Mail mit deinem persönlichen Downloadlink.',
  save_pictures: 'Bilder speichern',
  delete_pictures: 'Bilder löschen',
  upload_in_progress: 'Upload läuft',
  change_language: 'Sprache ändern',
  change_language_info: 'Deine Gäste können everlense auf Englisch und auf Deutsch nutzen. Die Sprache passt sich dabei automatisch an das jeweilige Gerät des Gastes an.',
  multiple_languages_1: 'Mehrsprachigkeit',
  multiple_languages_2: '(Englisch & Deutsch)',
  okay: 'Alles klar!',
  download_pictures: 'Bilder herunterladen',
  max_ten_images_go_to_settings: 'Maximal 10 Bilder erlaubt. Gehe in die Einstellungen, um alle Bilder herunterzuladen.',
  your_images_are_ready: 'Juhu, deine Bilder sind fertig!',
  here_are_your_images: 'Hier sind die Bilder deiner Veranstaltung {event}. Viel Spaß!',
  ps_mass_download: 'PS: Lade dir die Teile einfach nacheinander herunter. Dies wird nur als ein Massendownload gewertet.',
  download_here: 'Hier herunterladen',
  download_part: 'Teil {part} herunterladen',
  switch_language: 'Sprache ändern',
  tasks_choose_language: 'Welche Sprache sollen die Beispielaufgaben haben?',
  successfull_task_recreation: 'Sprache der Beispielaufgaben wurde geändert',
  something_went_wrong: 'Etwas ist schiefgelaufen',
  a_small_present_for_you: 'Ein kleines Geschenk für dich!',
  present_description: 'Du willst everlense gerne auf deinem Event nutzen? <span class="font-medium">Dann haben wir eine Überraschung für dich</span>. Wir schicken sie dir an deine E-Mail-Adresse.',
  your_email_address: 'Deine E-Mail Adresse',
  save_discount: 'Absenden',
  accept_agb_and_mail: 'Ich stimme den <a href="https://www.everlense.de/agb" class="font-medium cursor-pointer">AGB</a> zu und möchte E-Mails von everlense erhalten.',
  thank_you_for_sending: 'Wir schicken dir innerhalb der nächsten 15 Minuten eine E-Mail mit deiner persönlichen Überraschung.',
  discount_saved: 'Überraschung gesichert!',
  was_reported: 'Ein Gast hat dieses {type} gemeldet.',
  what_shall_happen: 'Was soll mit diesem {type} passieren?',
  edit_reporting_description: 'Bitte prüfe das {type} und entscheide, ob es wieder freigegeben oder endgültig gelöscht werden soll. Wir haben das Bild in der Zwischenzeit ausgeblendet.',
  how_did_you_get_to_know_us: 'Wie bist du auf everlense aufmerksam geworden?',
  please_select: 'Bitte auswählen',
  friends_and_family: 'Freunde & Bekannte',
  seen_at_a_wedding: 'Auf einer Hochzeit gesehen',
  other: 'Sonstiges',
  settings_deactive_challenge_text_download: 'Bilder beim Download beschriften',
  your_payment_is_processing: 'Deine Zahlung wird gerade verarbeitet. Bitte habe einen Moment Geduld.'
};