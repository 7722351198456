export const phrases = {
  your_party: "La tua festa.",
  all_pictures: "Tutte le immagini.",
  forgot_password: "Hai dimenticato la password?",
  no_account_yet: "Non hai ancora un account? Registrati ora.",
  send_your_event: "Invita i tuoi ospiti",
  scan_here: "Scansiona qui",
  show_more_replies: "Vedi altre risposte",
  shared_image_text: "Guarda questa foto scattata ad un evento con https://www.everlense.de",
  image_not_available_anymore: "Questa immagine non è più disponibile",
  add_images: "Aggiungi immagini",
  capture_photo: "Scatta una foto",
  enter_your_name: "Inserisci il tuo nome",
  your_photo_app_for_weddings: "La tua app fotografica per matrimoni",
  invite_guests: "Invita gli ospiti",
  delete_demo_data: "Cancella i dati demo",
  event_description_placeholder: "Cos'altro vorresti dire ai tuoi ospiti? Vai alle impostazioni per personalizzare la descrizione.",
  start_slideshow: "Inizia la presentazione qui",
  slideshow_steps_1: "Vai a con il tuo dispositivo",
  slideshow_steps_2: "e inserisci quanto segue",
  slideshow_steps_3: "Codice a 6 cifre",
  slideshow_steps_4: "in.",
  print_tasks: "Attività di stampa",
  edit_tasks: "Gestisci le attività",
  challenge_tips_and_tricks_1: "Suggerimenti e trucchi per",
  challenge_tips_and_tricks_2: "La tua sfida perfetta",
  challenge_tips_and_tricks_content_1: "Stampa il tuo modello su carta spessa.",
  challenge_tips_and_tricks_content_2: "Distribuisci i compiti a ciascun posto prima della festa.",
  challenge_tips_and_tricks_content_3: "Informate quante più persone possibile della sfida fotografica.",
  challenge_tips_and_tricks_content_4: "Utilizza lo slideshow dal vivo in modo che tutti gli invitati possano vedere le istantanee in tempo reale.",
  print_tasks_intro: "Per assicurarti che i tuoi ospiti possano trovare i compiti della sfida fotografica, dovresti esporli alla festa.",
  print_tasks_title_1: "Biglietti da stampare (popolari)",
  print_tasks_title_2: "Esporta solo i codici QR (e progetta tu stesso i biglietti)",
  print_tasks_action_1: "Scarica i PDF",
  print_tasks_action_2: "Scarica i codici QR",
  print_tasks_description_1: "I tuoi ospiti potranno partecipare alla sfida fotografica utilizzando i codici QR presenti sui nostri biglietti stampabili.",
  print_tasks_description_2: "Scarica i codici QR per iniziare la sfida fotografica e usali per creare i tuoi biglietti. C'è un codice QR per ogni attività.",
  edit_tasks_title_1: "Seleziona le attività",
  edit_tasks_title_2: "per i tuoi ospiti",
  really_want_to_delete_task: "Cancellare davvero l'attività?",
  add_task_button: "Aggiungi attività",
  delete_all_tasks_button: "Cancella tutto",
  settings_event_name_header: "Come dovrebbe chiamarsi il tuo evento?",
  settings_event_description_header: "Cos'altro devono sapere i tuoi ospiti?",
  settings_event_name_label: "Nome dell'evento",
  settings_event_description_label: "Descrizione dell'evento",
  settings_password_title: "Cambia la password",
  settings_old_password_header: "Vecchia password",
  settings_new_password_header: "Nuova password",
  settings_new_password_repeat_header: "Ripeti la nuova password",
  settings_invite_admin_title: "Aggiungi amministratori",
  settings_invite_admin_header: "Indirizzo e-mail del nuovo amministratore",
  send_invite: "Invia l'invito",
  settings_deactivate_challenge_title: "Disattiva la sfida",
  settings_deactivate_challenge_header: "Attiva/disattiva la sfida",
  settings_delete_data_title: "Elimina l'account",
  settings_delete_data_header: "Elimina definitivamente tutti i dati",
  delete_event: "Elimina l'evento",
  delete_event_toast_content: "Clicca su \"Elimina\" per chiudere definitivamente il tuo account. ATTENZIONE: tutte le immagini, i video e i dati caricati andranno irrimediabilmente persi e non potranno più essere ripristinati, nemmeno dal team di everlense.",
  settings_legal: "Questioni legali",
  settings_legal_content_1: "Con la creazione del tuo evento hai",
  settings_legal_content_2: "GTC",
  settings_legal_content_3: " e il nostro",
  settings_legal_content_4: "Informativa sulla privacy ",
  settings_legal_content_5: "accettato. Se vuoi saperne di più su everlense, visita il sito ",
  settings_legal_content_6: "Impronta.",
  reset_password: "Reimposta la password",
  register_phrase: "Abbiamo bisogno del tuo indirizzo e-mail per finalizzare l'organizzazione del tuo evento.",
  register_phrase_booked: "La prenotazione è andata a buon fine! Abbiamo bisogno del tuo indirizzo e-mail per finalizzare l'organizzazione del tuo evento.",
  email_address: "Indirizzo e-mail",
  register_legal: "Iscrivendoti accetti i nostri <a class=\"underline\" href=\"https://www.everlense.de/agb\">termini e condizioni</a> e la nostra <a class=\"underline\" href=\"https://www.everlense.de/datenschutz\">politica sulla privacy</a>. Se vuoi saperne di più su everlense, visita la nostra <a class=\"underline\" href=\"https://www.everlense.de/impressum\">nota legale</a>. Iscrivendoti, accetti anche di ricevere e-mail di marketing da parte nostra. Puoi revocare questo consenso in qualsiasi momento.",
  install_everlense: "Installa everlense",
  install_content_1: "Installa everlense sulla tua schermata iniziale per non dimenticare mai l'evento.",
  install_content_2: "Clicca",
  install_content_3: "e poi \"Vai alla schermata iniziale\".",
  install_content_4: "Più tardi",
  install_content_5: "Bene!",
  no_images_yet: "Non sono ancora state caricate immagini. Premi il pulsante più per caricare un'immagine.",
  delete_all_tasks_really: "Cancellare davvero tutte le attività?",
  your_task: "Il tuo compito",
  change_colors: "Cambia i colori",
  primary_color: "Colore primario",
  background_color: "Colore di sfondo",
  reset: "Azzeramento",
  choose_color: "Seleziona il colore",
  upgrade_package: "Pacchetto di aggiornamento",
  upgrade_required: "Aggiornamento richiesto",
  current_package: "Pacchetto attuale",
  used_image_count: "{used} delle immagini di {max} .",
  deleted_image_count: "{not_deleted} attualmente nella tua galleria, {deleted} è stato cancellato.",
  used_downloads_count: "{used} utilizzato da {max} per i download di massa.",
  most_popular: "I più popolari",
  price: '{price} Euro',
  max_media: "max. {count} immagini",
  max_guests: "max. {count} Ospiti",
  infinite_guests: "Numero illimitato di ospiti",
  storage_duration: "{duration} Mesi Periodo di conservazione",
  max_bulk_downloads: "download gratuito di massa | download gratuito di massa",
  custom_colors: "Colori individuali",
  multiple_admins: "Più amministratori",
  whatsapp_support: "Assistenza Premium per WhatsApp",
  go_to_checkout: "Alla cassa",
  start_setup: "Avvia la configurazione",
  upgrade_toast_content: "Per poter utilizzare questa funzione, devi aggiornare il tuo pacchetto everlense. Dai un'occhiata alle varie opzioni. ",
  no_thank_you: "No, grazie.",
  i_want_to_know_more: "Per saperne di più",
  demo_event_title: "Lisa e Jan",
  how_do_you_want_to_reedem: "Come vuoi riscattare il tuo pacco?",
  currently_logged_in_event: "Sei attualmente collegato all'evento: {name} Vuoi applicare il tuo acquisto a questo evento?",
  choose_event: "A quale evento vuoi destinare il tuo acquisto?",
  use_for_current_event: "evento attuale",
  use_for_existing_event: "evento esistente",
  use_for_new_event: "nuovo evento",
  settings_event_date_header: "Quando si svolge il tuo evento?",
  settings_event_date_label: "Data dell'evento",
  sort: "Ordinamento",
  time_of_recording: "Tempo di registrazione",
  time_of_upload: "Tempo di caricamento",
  lets_setup_event: "Organizziamo insieme il tuo evento",
  we_will_help_you_setup: "Ti aiutiamo a creare il tuo evento everlense in soli 2 minuti. Ti va di farlo?",
  what_is_the_name: "Quale dovrebbe essere il nome del tuo evento?",
  cancel_setup: "Annulla l'impostazione",
  enter_event_name_here: "Inserisci qui il nome dell'evento",
  when_does_event_take_place: "Quando si svolge il tuo evento?",
  enter_event_date_here: "Inserisci qui la data dell'evento",
  nice_please_upload_photo: "Molto bello. Carica una foto dell'evento per personalizzarlo ancora di più.",
  select_photo: "Seleziona la foto",
  what_do_you_want_to_describe: "Wow! C'è qualcos'altro che vorresti dire ai tuoi ospiti?",
  enter_description_here: "Inserisci qui la descrizione",
  finish_setup: "Finalizzare la configurazione",
  successfull_save: "Salvata con successo",
  successfull_deletion: "Eliminato con successo",
  copied_link: "Link copiato",
  booking_successfull: "Prenotazione andata a buon fine",
  completed_setup: "Installazione completata",
  sent_invite: "Invito inviato",
  initiated_payout: "Pagamento creato",
  sent_email: "E-mail inviata",
  max_ten_images: "Massimo 10 immagini alla volta.",
  reached_download_limit: "Il limite di download dell'evento è stato raggiunto.",
  reached_mass_download_limit: "Raggiunto il numero massimo di download di massa.",
  no_images_were_uploaded: "Non sono state ancora caricate immagini.",
  booking_failed: "Prenotazione fallita.",
  package_already_retrieved: "Pacchetto già riscattato",
  download_all_pictures: "Scarica tutte le immagini",
  notice: "Suggerimento",
  in_your_package: "Nel tuo pacco",
  bulk_download_modal_2: "di tutte le foto in una volta sola. Se ora clicchi su \"Scarica\", potrai consumare",
  bulk_download_modal_3: "uno di questi",
  bulk_download_modal_4: "questo",
  bulk_download_modal_5: "scaricare",
  bulk_download_modal_6: "Hai esaurito tutti i download di massa del tuo pacchetto. Prenota un aggiornamento per scaricare tutte le immagini.",
  bulk_download_modal_7: "Attendere prego. Le tue immagini sono in fase di preparazione per il download.",
  donwload_modal_1: '',
  donwload_modal_2: "scaricare davvero?",
  delete_modal_1: '',
  delete_modal_2: "Davvero cancellare?",
  download_preparation: "Il tuo download è in fase di preparazione. Riceverai un'e-mail con il tuo link personale per il download entro 15 minuti al massimo.",
  save_pictures: "Salva le immagini",
  delete_pictures: "Elimina le immagini",
  upload_in_progress: "Carica in corsa",
  change_language: "Cambia lingua",
  change_language_info: "Abbiamo sviluppato everlense in modo che la lingua si adatti automaticamente alla lingua del browser dei dispositivi dei tuoi ospiti. Quindi, se hai ospiti internazionali al tuo matrimonio, questo non è un problema e la galleria fotografica è sempre comprensibile per tutti.",
  multiple_languages_1: "Multilinguismo",
  multiple_languages_2: "(Inglese e tedesco)",
  okay: "Bene!",
  download_pictures: "Scarica le immagini",
  max_ten_images_go_to_settings: "Sono consentite al massimo 10 immagini. Vai alle impostazioni per scaricare tutte le immagini.",
  your_images_are_ready: "Evviva, le tue foto sono pronte!",
  here_are_your_images: "Ecco le foto del tuo evento {event}. Buon divertimento!",
  ps_mass_download: "PS: scarica semplicemente le parti una dopo l'altra. Questo verrà considerato solo come un download di massa.",
  download_here: "Scarica qui",
  download_part: "Scarica la parte {part}",
  switch_language: "Cambia lingua",
  tasks_choose_language: "In che lingua dovrebbero essere i compiti campione?",
  successfull_task_recreation: "La lingua dei compiti di esempio è stata modificata",
  something_went_wrong: "Qualcosa è andato storto",
  a_small_present_for_you: "Un piccolo regalo per te!",
  present_description: "Vuoi utilizzare everlense al tuo evento? <span class=\"font-medium\">Allora abbiamo una sorpresa per te</span>. Te lo invieremo al tuo indirizzo e-mail.",
  your_email_address: "Il tuo indirizzo e-mail",
  save_discount: "Invio",
  accept_agb_and_mail: "Accetto i <a href=\"https://www.everlense.de/agb\" class=\"font-medium cursor-pointer\">termini e le condizioni</a> e desidero ricevere e-mail da everlense.",
  thank_you_for_sending: "Ti invieremo un'e-mail con la tua sorpresa personalizzata entro i prossimi 15 minuti.",
  discount_saved: "Sorpresa assicurata!",
  was_reported: "{type} Un ospite ha segnalato questo .",
  what_shall_happen: "{type} Cosa dovrebbe accadere a questo?",
  edit_reporting_description: "{type} Ti invitiamo a controllare e a decidere se deve essere pubblicato di nuovo o se deve essere eliminato definitivamente. Nel frattempo abbiamo nascosto l'immagine.",
  how_did_you_get_to_know_us: "Come hai conosciuto everlense?",
  please_select: "Seleziona",
  friends_and_family: "Amici e conoscenti",
  seen_at_a_wedding: "Visto a un matrimonio",
  other: "Varie",
  settings_deactive_challenge_text_download: "Etichettatura delle immagini durante il download",
  your_payment_is_processing: "Il tuo pagamento è in fase di elaborazione. Ti preghiamo di pazientare un attimo."
};