export const phrases = {
  your_party: "Tu fiesta.",
  all_pictures: "Todas las fotos.",
  forgot_password: "¿Has olvidado tu contraseña?",
  no_account_yet: "¿Aún no tienes cuenta? Regístrate ahora.",
  send_your_event: "Invita a tus invitados",
  scan_here: "Escanea aquí",
  show_more_replies: "ver más respuestas",
  shared_image_text: "Mira esta foto tomada en un acto con https://www.everlense.de",
  image_not_available_anymore: "Esta imagen ya no está disponible",
  add_images: "Añadir imágenes",
  capture_photo: "Haz una foto",
  enter_your_name: "Introduce tu nombre",
  your_photo_app_for_weddings: "Tu aplicación de fotos para bodas",
  invite_guests: "Invita a los invitados",
  delete_demo_data: "Borrar datos de demostración",
  event_description_placeholder: "¿Qué más quieres decir a tus invitados? Ve a la configuración para personalizar la descripción.",
  start_slideshow: "Iniciar presentación aquí",
  slideshow_steps_1: "Ir a con tu dispositivo",
  slideshow_steps_2: "e introduce lo siguiente",
  slideshow_steps_3: "Código de 6 dígitos",
  slideshow_steps_4: "en.",
  print_tasks: "Tareas de impresión",
  edit_tasks: "Gestionar tareas",
  challenge_tips_and_tricks_1: "Consejos y trucos para",
  challenge_tips_and_tricks_2: "Tu reto perfecto",
  challenge_tips_and_tricks_content_1: "Imprime tu plantilla en papel grueso.",
  challenge_tips_and_tricks_content_2: "Distribuye las tareas a cada asiento antes de la fiesta.",
  challenge_tips_and_tricks_content_3: "Informa al mayor número posible de personas sobre el reto fotográfico.",
  challenge_tips_and_tricks_content_4: "Utiliza el pase de diapositivas en directo para que todos los invitados puedan ver las instantáneas en tiempo real.",
  print_tasks_intro: "Debes exponerlas en la fiesta para que tus invitados puedan encontrar las tareas del reto fotográfico.",
  print_tasks_title_1: "Tarjetas para imprimir (populares)",
  print_tasks_title_2: "Exporta sólo códigos QR (y diseña tú mismo las tarjetas)",
  print_tasks_action_1: "Descargar PDF",
  print_tasks_action_2: "Descargar códigos QR",
  print_tasks_description_1: "Tus invitados pueden unirse al reto fotográfico utilizando los códigos QR de nuestras tarjetas imprimibles.",
  print_tasks_description_2: "Descarga los códigos QR para iniciar el reto fotográfico y utilízalos para diseñar tus propias tarjetas. Hay un código QR por tarea.",
  edit_tasks_title_1: "Selecciona las tareas",
  edit_tasks_title_2: "para tus invitados",
  really_want_to_delete_task: "¿Borrar realmente la tarea?",
  add_task_button: "Añadir tarea",
  delete_all_tasks_button: "Borrar todo",
  settings_event_name_header: "¿Cómo debe llamarse tu acto?",
  settings_event_description_header: "¿Qué más necesitan saber tus invitados?",
  settings_event_name_label: "Nombre del acto",
  settings_event_description_label: "Descripción del acto",
  settings_password_title: "Cambiar contraseña",
  settings_old_password_header: "Contraseña antigua",
  settings_new_password_header: "Nueva contraseña",
  settings_new_password_repeat_header: "Repite la nueva contraseña",
  settings_invite_admin_title: "Añadir administradores",
  settings_invite_admin_header: "Dirección de correo electrónico del nuevo administrador",
  send_invite: "Enviar invitación",
  settings_deactivate_challenge_title: "Desactivar desafío",
  settings_deactivate_challenge_header: "Activar/desactivar el reto",
  settings_delete_data_title: "Eliminar cuenta",
  settings_delete_data_header: "Borrar permanentemente todos los datos",
  delete_event: "Eliminar evento",
  delete_event_toast_content: "Haz clic en \"Eliminar\" para cerrar tu cuenta definitivamente. ATENCIÓN: Todas las imágenes, vídeos y datos subidos se perderán irrevocablemente y ya no podrán ser restaurados, ni siquiera por el equipo de everlense.",
  settings_legal: "Asuntos jurídicos",
  settings_legal_content_1: "Con la creación de tu evento tienes",
  settings_legal_content_2: "GTC",
  settings_legal_content_3: " y nuestro",
  settings_legal_content_4: "Política de privacidad ",
  settings_legal_content_5: "aceptada. Si quieres saber más sobre everlense, visita nuestro ",
  settings_legal_content_6: "Pie de imprenta.",
  reset_password: "Restablecer contraseña",
  register_phrase: "Necesitamos tu dirección de correo electrónico para ultimar la organización de tu acto.",
  register_phrase_booked: "¡La reserva se ha realizado correctamente! Necesitamos tu dirección de correo electrónico para finalizar el montaje de tu evento.",
  email_address: "Dirección de correo electrónico",
  register_legal: "Al unirte, aceptas nuestros <a class=\"underline\" href=\"https://www.everlense.de/agb\">términos y condiciones</a> y nuestra <a class=\"underline\" href=\"https://www.everlense.de/datenschutz\">política de privacidad</a>. Si quieres saber más sobre everlense, visita nuestro <a class=\"underline\" href=\"https://www.everlense.de/impressum\">aviso legal</a>. Al inscribirte, también aceptas recibir correos electrónicos de marketing nuestros. Puedes revocar este consentimiento en cualquier momento.",
  install_everlense: "Instalar everlense",
  install_content_1: "Instala everlense en tu pantalla de inicio para no olvidar nunca el evento.",
  install_content_2: "Haz clic en",
  install_content_3: "y luego \"Ir a la pantalla de inicio\".",
  install_content_4: "Más tarde",
  install_content_5: "¡Muy bien!",
  no_images_yet: "Aún no se ha subido ninguna foto. Pulsa el botón más para subir una foto.",
  delete_all_tasks_really: "¿Borrar realmente todas las tareas?",
  your_task: "Tu tarea",
  change_colors: "Cambiar colores",
  primary_color: "Color primario",
  background_color: "Color de fondo",
  reset: "Restablece",
  choose_color: "Selecciona el color",
  upgrade_package: "Paquete de actualización",
  upgrade_required: "Actualización necesaria",
  current_package: "Paquete actual",
  used_image_count: "{used} de las imágenes de {max} .",
  deleted_image_count: "{not_deleted} actualmente en tu galería, {deleted} eliminado.",
  used_downloads_count: "{used} utilizado por {max} descargas masivas.",
  most_popular: "Más populares",
  price: '{price} Euro',
  max_media: "máx. {count} imágenes",
  max_guests: "máx. {count} Invitados",
  infinite_guests: "Número ilimitado de invitados",
  storage_duration: "{duration} Meses Periodo de almacenamiento",
  max_bulk_downloads: "descarga masiva gratuita | descargas masivas gratuitas",
  custom_colors: "Colores individuales",
  multiple_admins: "Varios administradores",
  whatsapp_support: "Asistencia Premium WhatsApp",
  go_to_checkout: "A la caja",
  start_setup: "Iniciar configuración",
  upgrade_toast_content: "Para poder utilizar esta función, debes actualizar tu paquete everlense. Echa un vistazo a las distintas opciones. ",
  no_thank_you: "No, gracias.",
  i_want_to_know_more: "Saber más",
  demo_event_title: "Lisa y Jan",
  how_do_you_want_to_reedem: "¿Cómo quieres canjear tu paquete?",
  currently_logged_in_event: "Estás conectado al evento: {name} ¿Quieres aplicar tu compra a este evento?",
  choose_event: "¿A qué evento te gustaría aplicar tu compra?",
  use_for_current_event: "acontecimiento actual",
  use_for_existing_event: "evento existente",
  use_for_new_event: "nuevo evento",
  settings_event_date_header: "¿Cuándo se celebra tu acto?",
  settings_event_date_label: "Fecha del acontecimiento",
  sort: "Clasificar",
  time_of_recording: "Tiempo de grabación",
  time_of_upload: "Tiempo de carga",
  lets_setup_event: "Organicemos juntos tu evento",
  we_will_help_you_setup: "Te ayudaremos a organizar tu evento everlense en sólo 2 minutos. ¿Te apetece?",
  what_is_the_name: "¿Cuál debe ser el nombre de tu acto?",
  cancel_setup: "Cancelar configuración",
  enter_event_name_here: "Introduce aquí el nombre del evento",
  when_does_event_take_place: "¿Cuándo se celebra tu acto?",
  enter_event_date_here: "Introduce aquí la fecha del evento",
  nice_please_upload_photo: "Muy bonito. Sube ahora una foto del evento para personalizarlo aún más",
  select_photo: "Seleccionar foto",
  what_do_you_want_to_describe: "¡Guau! ¿Algo más que quieras decir a tus invitados?",
  enter_description_here: "Introduce aquí la descripción",
  finish_setup: "Finalizar la configuración",
  successfull_save: "Guardado correctamente",
  successfull_deletion: "Eliminado con éxito",
  copied_link: "Enlace copiado",
  booking_successfull: "Reserva con éxito",
  completed_setup: "Instalación finalizada",
  sent_invite: "Invitación enviada",
  initiated_payout: "Pago creado",
  sent_email: "Correo electrónico enviado",
  max_ten_images: "Máximo 10 imágenes a la vez.",
  reached_download_limit: "Límite de descarga del evento alcanzado.",
  reached_mass_download_limit: "Número máximo de descargas masivas alcanzado.",
  no_images_were_uploaded: "Aún no se ha subido ninguna foto.",
  booking_failed: "Reserva fallida.",
  package_already_retrieved: "Paquete ya canjeado",
  download_all_pictures: "Descargar todas las imágenes",
  notice: "Sugerencia",
  in_your_package: "En tu paquete",
  bulk_download_modal_2: "de todas las fotos a la vez. Si ahora haces clic en \"Descargar\" consumirás",
  bulk_download_modal_3: "uno de estos",
  bulk_download_modal_4: "este",
  bulk_download_modal_5: "descargar",
  bulk_download_modal_6: "Has agotado todas las descargas masivas de tu paquete. Reserva una actualización para descargar todas las imágenes.",
  bulk_download_modal_7: "Por favor, espera. Tus imágenes se están preparando para la descarga.",
  donwload_modal_1: '',
  donwload_modal_2: "¿descargar realmente?",
  delete_modal_1: '',
  delete_modal_2: "¿borrar de verdad?",
  download_preparation: "Se está preparando tu descarga. Recibirás un correo electrónico con tu enlace de descarga personal en 15 minutos a más tardar.",
  save_pictures: "Guardar imágenes",
  delete_pictures: "Borrar imágenes",
  upload_in_progress: "Cargar en marcha",
  change_language: "Cambiar idioma",
  change_language_info: "Hemos desarrollado everlense para que el idioma se adapte automáticamente al idioma del navegador de los dispositivos de tus invitados. Así, si tienes invitados internacionales en tu boda, esto no supone ningún problema y la galería de fotos siempre será comprensible para todos.",
  multiple_languages_1: "Multilingüismo",
  multiple_languages_2: "(inglés y alemán)",
  okay: "¡Muy bien!",
  download_pictures: "Descargar imágenes",
  max_ten_images_go_to_settings: "Se permite un máximo de 10 imágenes. Ve a la configuración para descargar todas las imágenes.",
  your_images_are_ready: "Yay, ¡tus fotos están listas!",
  here_are_your_images: "Aquí tienes las fotos de tu evento {event}. ¡Diviértete!",
  ps_mass_download: "PD: Simplemente descarga las partes una tras otra. Esto sólo se contará como una descarga masiva.",
  download_here: "Descargar aquí",
  download_part: "Descarga la pieza {part}",
  switch_language: "Cambiar idioma",
  tasks_choose_language: "¿Qué idioma deben tener las tareas de muestra?",
  successfull_task_recreation: "Se ha cambiado el idioma de las tareas de ejemplo",
  something_went_wrong: "Algo ha ido mal",
  a_small_present_for_you: "¡Un regalito para ti!",
  present_description: "¿Te gustaría utilizar everlense en tu evento? <span class=\"font-medium\">Entonces tenemos una sorpresa para ti</span>. Te la enviaremos a tu dirección de correo electrónico.",
  your_email_address: "Tu dirección de correo electrónico",
  save_discount: "Envío de",
  accept_agb_and_mail: "Acepto los <a href=\"https://www.everlense.de/agb\" class=\"font-medium cursor-pointer\">términos y condiciones</a> y me gustaría recibir correos electrónicos de everlense.",
  thank_you_for_sending: "Te enviaremos un correo electrónico con tu sorpresa personalizada en los próximos 15 minutos.",
  discount_saved: "¡Sorpresa asegurada!",
  was_reported: "{type} Un huésped ha informado de esto .",
  what_shall_happen: "{type} ¿Qué debería pasar con esto?",
  edit_reporting_description: "{type} Por favor, compruébalo y decide si debe publicarse de nuevo o eliminarse permanentemente. Mientras tanto, hemos ocultado la imagen.",
  how_did_you_get_to_know_us: "¿Cómo conociste everlense?",
  please_select: "Selecciona",
  friends_and_family: "Amigos y conocidos",
  seen_at_a_wedding: "Visto en una boda",
  other: "Varios",
  settings_deactive_challenge_text_download: "Etiquetar imágenes durante la descarga",
  your_payment_is_processing: "Se está procesando tu pago. Por favor, ten un poco de paciencia."
};