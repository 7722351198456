export const phrases = {
  your_party: "Ta fête",
  all_pictures: "Toutes les photos",
  forgot_password: "Mot de passe oublié ?",
  no_account_yet: "Tu n'as pas encore de compte ? Inscris-toi maintenant",
  send_your_event: "Invite tes invités",
  scan_here: "Scanner ici",
  show_more_replies: "voir d'autres réponses",
  shared_image_text: "Regarde quelle photo a été prise lors d'un événement avec https://www.everlense.de",
  image_not_available_anymore: "Cette image n'est plus disponible",
  add_images: "Ajouter des images",
  capture_photo: "Prendre une photo",
  enter_your_name: "Saisis ton nom",
  your_photo_app_for_weddings: "Ton application photo pour les mariages",
  invite_guests: "Inviter des invités",
  delete_demo_data: "Supprimer les données de démonstration",
  event_description_placeholder: "Que veux-tu encore dire à tes invités ? Va dans les paramètres pour personnaliser la description.",
  start_slideshow: "Démarrer le diaporama ici",
  slideshow_steps_1: "Avec ton appareil, va sur",
  slideshow_steps_2: "et entrez le code suivant",
  slideshow_steps_3: "code à 6 chiffres",
  slideshow_steps_4: "un.",
  print_tasks: "Imprimer les tâches",
  edit_tasks: "Gérer les tâches",
  challenge_tips_and_tricks_1: "Conseils et astuces pour",
  challenge_tips_and_tricks_2: "ton challenge parfait",
  challenge_tips_and_tricks_content_1: "Imprime ton modèle sur du papier épais.",
  challenge_tips_and_tricks_content_2: "Distribue les tâches à chaque place avant ta célébration.",
  challenge_tips_and_tricks_content_3: "Parle du défi photo à autant de personnes que possible.",
  challenge_tips_and_tricks_content_4: "Utilise le diaporama en direct pour que tous les invités puissent voir les clichés en temps réel.",
  print_tasks_intro: "Pour que tes invités trouvent les épreuves du défi photo, tu dois les afficher lors de la fête.",
  print_tasks_title_1: "Cartes à imprimer (populaires)",
  print_tasks_title_2: "Exporter uniquement des codes QR (et créer soi-même des cartes)",
  print_tasks_action_1: "Télécharger des PDF",
  print_tasks_action_2: "Télécharger les codes QR",
  print_tasks_description_1: "Grâce aux codes QR figurant sur nos cartes imprimables, tes invités peuvent participer au défi photo.",
  print_tasks_description_2: "Télécharger les codes QR pour lancer le défi photo et les utiliser pour créer ses propres cartes. Il y a un code QR par défi.",
  edit_tasks_title_1: "Choisis les tâches",
  edit_tasks_title_2: "pour tes invités",
  really_want_to_delete_task: "Supprimer vraiment la tâche ?",
  add_task_button: "Ajouter une tâche",
  delete_all_tasks_button: "Supprimer tout",
  settings_event_name_header: "Quel sera le nom de ton événement ?",
  settings_event_description_header: "Que doivent encore savoir tes invités ?",
  settings_event_name_label: "Nom de l´événement",
  settings_event_description_label: "Description de l´événement",
  settings_password_title: "Modifier le mot de passe",
  settings_old_password_header: "Ancien mot de passe",
  settings_new_password_header: "Nouveau mot de passe",
  settings_new_password_repeat_header: "Répéter le nouveau mot de passe",
  settings_invite_admin_title: "Ajouter des admins",
  settings_invite_admin_header: "Adresse e-mail du nouvel administrateur",
  send_invite: "Envoyer l´invitation",
  settings_deactivate_challenge_title: "Désactiver le challenge",
  settings_deactivate_challenge_header: "Activer/désactiver le challenge",
  settings_delete_data_title: "Effacer les données",
  settings_delete_data_header: "Supprimer définitivement toutes les données",
  delete_event: "Supprimer un événement",
  delete_event_toast_content: "Clique sur \"Supprimer\" pour fermer définitivement ton événement. ATTENTION : Toutes les images, vidéos et données téléchargées sont ensuite irrémédiablement perdues et ne peuvent plus être récupérées, même par l´équipe everlense.",
  settings_legal: "Mentions légales",
  settings_legal_content_1: "En créant ton événement, tu as fait appel à notre",
  settings_legal_content_2: "CONDITIONS GÉNÉRALES DE VENTE",
  settings_legal_content_3: " et notre",
  settings_legal_content_4: "Déclaration de confidentialité ",
  settings_legal_content_5: "accepté. Si tu veux en savoir plus sur everlense, visite notre ",
  settings_legal_content_6: "Mentions légales.",
  reset_password: "Réinitialiser le mot de passe",
  register_phrase: "Pour terminer la mise en place de ton événement, nous avons besoin de ton adresse e-mail.",
  register_phrase_booked: "La réservation a été effectuée avec succès ! Pour terminer la mise en place de ton événement, nous avons besoin de ton adresse e-mail.",
  email_address: "Adresse électronique",
  register_legal: "En t'inscrivant, tu acceptes nos <a class=\"underline\" href=\"https://www.everlense.de/agb\">conditions générales</a> et notre <a class=\"underline\" href=\"https://www.everlense.de/datenschutz\">déclaration de confidentialité</a>. Si tu veux en savoir plus sur everlense, consulte nos <a class=\"underline\" href=\"https://www.everlense.de/impressum\">mentions légales</a>. En t'inscrivant, tu acceptes également de recevoir des e-mails marketing de notre part. Tu peux révoquer ce consentement à tout moment.",
  install_everlense: "Installer everlense",
  install_content_1: "Installe everlense sur ton écran d´accueil pour ne jamais oublier l´événement.",
  install_content_2: "Clique sur",
  install_content_3: "puis \"Vers l´écran d´accueil\".",
  install_content_4: "Plus tard",
  install_content_5: "Tout est clair !",
  no_images_yet: "Aucune image n´a encore été téléchargée. Appuie sur le plus pour télécharger une image.",
  delete_all_tasks_really: "Supprimer vraiment toutes les tâches ?",
  your_task: "Ta mission",
  change_colors: "Modifier les couleurs",
  primary_color: "Couleur primaire",
  background_color: "Couleur de fond",
  reset: "Réinitialiser",
  choose_color: "Choisir la couleur",
  upgrade_package: "Mettre à niveau le paquet",
  upgrade_required: "Mise à niveau nécessaire",
  current_package: "Paquet actuel",
  used_image_count: "{used} {max} images.",
  deleted_image_count: "{not_deleted} actuellement dans ta galerie, {deleted} supprimé.",
  used_downloads_count: "{used} de {max} téléchargements en masse utilisés.",
  most_popular: "Les plus populaires",
  price: '{price} Euro',
  max_media: "max. {count} images",
  max_guests: "max. {count} invités",
  infinite_guests: "nombre d'invités illimité",
  storage_duration: "{duration} mois Durée de stockage",
  max_bulk_downloads: "téléchargement de masse gratuit | téléchargements de masse gratuits",
  custom_colors: "Couleurs personnalisées",
  multiple_admins: "Plusieurs admins",
  whatsapp_support: "Support premium pour WhatsApp",
  go_to_checkout: "Vers le checkout",
  start_setup: "Démarrer l´installation",
  upgrade_toast_content: "Pour pouvoir utiliser cette fonction, tu dois mettre à jour ton paquet everlense. Regarde les différentes possibilités. ",
  no_thank_you: "Non, merci.",
  i_want_to_know_more: "En savoir plus",
  demo_event_title: 'Lisa & Jan',
  how_do_you_want_to_reedem: "Comment souhaites-tu échanger ton paquet ?",
  currently_logged_in_event: "Tu es actuellement connecté à l'événement : {name} . Tu veux appliquer ton achat à cet événement ?",
  choose_event: "Sur quel événement souhaites-tu appliquer ton achat ?",
  use_for_current_event: "événement actuel",
  use_for_existing_event: "Événement existant",
  use_for_new_event: "nouvel événement",
  settings_event_date_header: "Quand ton événement aura-t-il lieu ?",
  settings_event_date_label: "Date de l'événement",
  sort: "Trier",
  time_of_recording: "Date d'enregistrement",
  time_of_upload: "Moment du téléchargement",
  lets_setup_event: "Organisons ensemble ton événement",
  we_will_help_you_setup: "Nous t'aidons à mettre en place ton événement everlense en seulement 2 minutes. Tu as envie ?",
  what_is_the_name: "Quel sera le nom de ton événement ?",
  cancel_setup: "Annuler l'installation",
  enter_event_name_here: "Saisir ici le nom de l'événement",
  when_does_event_take_place: "Quand ton événement aura-t-il lieu ?",
  enter_event_date_here: "Entrer ici la date de l'événement",
  nice_please_upload_photo: "Très sympa ! Télécharge maintenant une photo de l'événement pour le rendre encore plus personnel",
  select_photo: "Choisir une photo",
  what_do_you_want_to_describe: "Wow ! Tu veux partager quelque chose avec tes invités ?",
  enter_description_here: "Entrer la description ici",
  finish_setup: "Terminer l'installation",
  successfull_save: "Enregistré avec succès",
  successfull_deletion: "Supprimé avec succès",
  copied_link: "Lien copié",
  booking_successfull: "Réservation réussie",
  completed_setup: "Aménagement terminé",
  sent_invite: "Invitation envoyée",
  initiated_payout: "Paiement créé",
  sent_email: "E-mail envoyé",
  max_ten_images: "Un maximum de 10 images à la fois.",
  reached_download_limit: "Limite de téléchargement de l'événement atteinte",
  reached_mass_download_limit: "Nombre maximum de téléchargements de masse atteint.",
  no_images_were_uploaded: "Aucune image n'a encore été téléchargée.",
  booking_failed: "Échec de la réservation.",
  package_already_retrieved: "Paquet déjà encaissé",
  download_all_pictures: "Télécharger toutes les images",
  notice: "Note",
  in_your_package: "Dans ton paquet",
  bulk_download_modal_2: "de toutes les photos à la fois. Si tu cliques maintenant sur \"télécharger\", tu consommes",
  bulk_download_modal_3: "un de ces",
  bulk_download_modal_4: "ce",
  bulk_download_modal_5: "Télécharger",
  bulk_download_modal_6: "Tu as utilisé tous les téléchargements en masse de ton pack. S'il te plaît, réserve une mise à niveau pour télécharger toutes les images.",
  bulk_download_modal_7: "Attends s'il te plaît. Tes images sont préparées pour le téléchargement.",
  donwload_modal_1: '',
  donwload_modal_2: "vraiment télécharger ?",
  delete_modal_1: '',
  delete_modal_2: "vraiment supprimer ?",
  download_preparation: "Ton téléchargement est en cours de préparation. Tu recevras un e-mail avec ton lien de téléchargement personnel dans 15 minutes au plus tard.",
  save_pictures: "Enregistrer les images",
  delete_pictures: "Supprimer les images",
  upload_in_progress: "Téléchargement en cours",
  change_language: "Changer de langue",
  change_language_info: "Nous avons développé everlense de manière à ce que la langue s'adapte automatiquement à la langue du navigateur des appareils de tes invités. Si tu as des invités internationaux à ton mariage, ce n'est pas un problème et la galerie de photos sera toujours compréhensible pour tous.",
  multiple_languages_1: "Multilinguisme",
  multiple_languages_2: "(anglais & allemand)",
  okay: "Tout est clair !",
  download_pictures: "Télécharger les images",
  max_ten_images_go_to_settings: "Maximum 10 images autorisées. Va dans les paramètres pour télécharger toutes les images.",
  your_images_are_ready: "Youpi, tes photos sont prêtes !",
  here_are_your_images: "Voici les images de ton événement {event}. Amuse-toi bien !",
  ps_mass_download: "PS : Télécharge simplement les parties l'une après l'autre. Cela ne sera considéré que comme un téléchargement en masse.",
  download_here: "Télécharger ici",
  download_part: "Télécharger la partie {part}",
  switch_language: "Changer de langue",
  tasks_choose_language: "Quelle est la langue des exemples de tâches ?",
  successfull_task_recreation: "La langue des exemples de tâches a été modifiée",
  something_went_wrong: "Quelque chose a mal tourné",
  a_small_present_for_you: "Un petit cadeau pour toi !",
  present_description: "Tu aimerais utiliser everlense lors de ton événement ? <span class=\"font-medium\">Alors nous avons une surprise pour toi</span>. Nous te l'enverrons à ton adresse e-mail.",
  your_email_address: "Ton adresse e-mail",
  save_discount: "Envoyer",
  accept_agb_and_mail: "J'accepte les <a href=\"https://www.everlense.de/agb\" class=\"font-medium cursor-pointer\">conditions générales</a> et je souhaite recevoir des e-mails de everlense.",
  thank_you_for_sending: "Nous t'enverrons un e-mail dans les 15 prochaines minutes avec ta surprise personnelle.",
  discount_saved: "Surprise assurée !",
  was_reported: "Un invité a signalé ce {type} .",
  what_shall_happen: "Que faire de ce {type} ?",
  edit_reporting_description: "Vérifie s'il te plaît le site {type} et décide s'il faut le valider à nouveau ou le supprimer définitivement. En attendant, nous avons supprimé l'image.",
  how_did_you_get_to_know_us: "Comment as-tu découvert everlense ?",
  please_select: "Choisir s'il te plaît",
  friends_and_family: "Amis et connaissances",
  seen_at_a_wedding: "Vu à un mariage",
  other: "Autres",
  settings_deactive_challenge_text_download: "Légender les images lors du téléchargement",
  your_payment_is_processing: "Ton paiement est en cours de traitement. Merci de patienter quelques instants."
};