import { createApp, h } from 'vue'
import { createInertiaApp } from '@inertiajs/vue3'
import { i18nInstance } from '../locales/locales'
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import '../css/app5.css'
import '../css/colors.css'
import '../css/fonts.css'
import '@fortawesome/fontawesome-free/css/all.css'

import { VueReCaptcha } from 'vue-recaptcha-v3'

import.meta.glob(['../assets/img/**'])
createInertiaApp({
    progress: {
        delay: 120,
        color: '#ffffff',
        includeCSS: true,
        showSpinner: true,
    },
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob(['./Pages/**/*.vue', '../assets/img/**']),
        ),

    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })

        Sentry.init({
            app,
            dsn: props.initialPage.props.sentryDsn,
            tracesSampleRate: 0.01,
            environment: props.initialPage.props.environment,
            trackComponents: true,
            logErrors: true,
            initialScope: {
                tags: {
                    event_id: props.initialPage.props.eventId,
                },
            },
        })

        app.use(plugin)
            .use(i18nInstance)
            .use(VueReCaptcha, {
                siteKey: props.initialPage.props.recaptchaPublicKey,
                loaderOptions: {
                    autoHideBadge: true,
                },
            })
            .mount(el)
    },
}).catch((err) => console.log(err))
