export const words = {
    email: 'E-Mail',
    Email: 'E-Mail',
    password: 'Passwort',
    login: 'Anmelden',
    pictures: 'Bilder',
    copy_link: 'Link Kopieren',
    share_with_whatsapp: 'via WhatsApp',
    qrdownload: 'Qr-Code Download',
    flyerdownload: 'Flyer Download',
    logout: 'Ausloggen',
    challenge: 'Foto-Challenge',
    preparation: 'Vorbereitung',
    photos: 'Fotos',
    challenges: 'Challenge',
    diashow: 'Live-Diashow',
    settings: 'Einstellungen',
    of: 'von',
    comments: 'Kommentare',
    liked: 'Gefällt',
    answer: 'Antworten',
    minutes: ' Minuten',
    hours: ' Stunden',
    days: ' Tage',
    yesterday: 'Gestern',
    hide: 'Ausblenden',
    share: 'Teilen',
    report: 'Melden',
    delete: 'Löschen',
    description: 'Beschreibung',
    enter: 'Beitreten',
    install: 'Installieren',
    or: 'oder',
    event: 'event',
    save: 'Speichern',
    cancel: 'Abbrechen',
    continue: 'Weiter',
    task: 'Aufgabe',
    take_photo: 'Foto aufnehmen',
    skip: 'Überspringen',
    color: 'Farbe',
    videos: 'Videos',
    video: 'Video',
    eur: 'Euro',
    usd: 'Dollar',

    packages: {
        free: 'Free',
        base: 'Basis',
        upgrade_base_to_premium: 'Premium',
        upgrade_base_to_deluxe: 'Deluxe',
        premium: 'Premium',
        upgrade_premium_to_deluxe: 'Deluxe',
        deluxe: 'Deluxe',
    },
    1: 'Ein',
    2: 'Zwei',
    3: 'Drei',
    4: 'Vier',
    5: 'Fünf',
    download: 'Herunterladen',
    are: 'sind',
    is: 'ist',
    mass_download: 'Massendownload',
    upgrade: 'Upgrade',
    start_now: 'Starten',
    live_diashow: 'Live-Diashow',
    code: 'Code',
    chosen: 'ausgewählt',
    all: 'Alle',
    picture: 'Bild',
    language: 'Sprache',
    languages: {
        de: 'Deutsch',
        en: 'Englisch',
        fr: 'Französisch',
        it: 'Italienisch',
        es: 'Spanisch',
    },
    close: 'Schließen',
    edit: 'Bearbeiten',
    release: 'Freigeben',
    check: 'Prüfen',
}
