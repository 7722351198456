export const words = {
  email: "e-mail",
  Email: "e-mail",
  password: "palavra-passe",
  login: "Iniciar sessão",
  pictures: "Imagens",
  copy_link: "Copia a ligação",
  share_with_whatsapp: 'via WhatsApp',
  qrdownload: "Descarrega Qr-Code",
  flyerdownload: "Descarrega o folheto",
  logout: "Termina a sessão",
  challenge: "Desafio fotográfico",
  preparation: "Preparação",
  photos: 'Fotos',
  challenges: "Desafio",
  diashow: "Apresentação de slides em direto",
  settings: "Definições",
  of: "de",
  comments: "Comentários",
  liked: "Gostas?",
  answer: "Respostas",
  minutes: " minutos",
  hours: " Horas",
  days: " Dias",
  yesterday: "Ontem",
  hide: "Esconde-te",
  share: "Partilha",
  report: "Relatório",
  delete: "Elimina",
  description: "Descrição do",
  enter: "Junta-te",
  install: "Instala",
  or: "ou",
  event: "evento",
  save: "Poupa",
  cancel: "Cancela",
  continue: "Mais",
  task: "Tarefa",
  take_photo: "Tira uma fotografia",
  skip: "Salta",
  color: "Cor",
  videos: "Vídeos",
  video: "Vídeo",
  eur: 'Euro',
  usd: "Dólar",
  packages: {
    free: "Livre",
    base: "Base",
    upgrade_base_to_premium: "Prémio",
    upgrade_base_to_deluxe: 'Deluxe',
    premium: "Prémio",
    upgrade_premium_to_deluxe: 'Deluxe',
    deluxe: 'Deluxe'
  },
  1: "A",
  2: "Dois",
  3: "Três",
  4: "Quatro",
  5: "Cinco",
  download: "Descarrega",
  are: "são",
  is: "é",
  mass_download: "Descarrega em massa",
  upgrade: "Atualização",
  start_now: "Começa",
  live_diashow: "Apresentação de slides em direto",
  code: "Código",
  chosen: "selecionado",
  all: "Todos",
  picture: "Imagem",
  language: "Língua",
  languages: {
    de: "Alemão",
    en: "Inglês",
    fr: "Francês",
    it: "Italiano",
    es: "Espanhol"
  },
  close: "Fecha",
  edit: "Edita",
  release: "Libertação",
  check: "Verifica"
};